import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Heading from "../../components/headline";
import DynamicContent from "../../components/dynamicContent";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
const parse = require("html-react-parser");

const LgbtqPage = () => {
  const data = useStaticQuery(query);

  console.log(data);
  const { strapiLgbtqia } = data;
  const { Content, Headline } = strapiLgbtqia;
  // console.log(Headline);
  return (
    <Layout>
      <Heading headline={Headline} />
      {/* <Typography variant="h2">{data.strapiHomepage.hero.title}</Typography> */}

      <div className={"dynamic-content"}>
        {Content.map((contentBlock) => (
          <DynamicContent block={contentBlock} />
        ))}
      </div>
    </Layout>
  );
};
const query = graphql`
  query {
    strapiLgbtqia {
      Content {
        textField
        question
        instagramUrl
        heading
        id
        eventName
        eventDescription
        blockName
        answer
        SublineLink {
          authorPublication
          discoverMore
          id
        }
      }
      Headline {
        blockName
        heading
        id
        textField
      }
      blockText
      name
      slug
    }
  }
`;

export default LgbtqPage;
